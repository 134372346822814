import './Manager.css';
import React from "react";
import background from "../assets/background.png";

function Manager(props) {
    const imageClassName = (props.isMobile) ? "MobileImg" : "Img";  

    return (
    <div className="manager">
    <img className={imageClassName} src={background} alt="orange marble tiles in a grid"/>

        <p className="moreDetails"> more details coming soon!</p>
   </div>
    )
}
export default Manager;
import './Eleve.css';
import React from "react";
import eleve from "../assets/images/eleve.png"

function Eleve(props) {

    return (
    <div className="eleve">
        <img className="eleveImage" src={eleve} alt="white text that says  eleve by byte"/>
        <p className="moreDetails"> more details coming soon </p>
   </div>
    )
}
export default Eleve;
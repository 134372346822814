import './Footer.css';
import React from "react";
import byteLogo from '../assets/images/byteLogo.png'
import footer from '../assets/images/footer.svg'
import arrow from '../assets/images/arrow.png'


function Footer(props) {
    
    return (
    <div className="footer">
        <img className="byteLogo" src={byteLogo} alt="byte icon, a robot chef wearing a teal blue chefs hat with some orange accents on his face"/>
        <img className="footerBackground" src={footer} alt="light blue background with an orange border"/>
        {/* <p className="footerBigText"> FULL STACK RESTAURANT KITCHEN SOFTWARE</p> */}
        <div className="meetByteButton">
            <p className="footerMeetByteTextSmall">say hi to</p>
            <p className="footerMeetByteText">BYTE:</p>
        </div>
        <p className="footerMediumText"> your new general manager, powered by ai. byte can handle inventory, prep optimization, customer satisfaction, and much much more.</p>
        <a className="footerButtonByte" href="/gm-byte">
        <p className="seeWhat"> see what byte can do</p>
        <img className="arrow" src={arrow} alt="dark grey beveled arrow"/>
        </a>
   </div>
    )
}
export default Footer;
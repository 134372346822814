import './Header.css';
import React from "react";
import byteText from '../assets/images/byteText.png'

function Header(props) {

    return (
    <div className="header">
        <img className="byteText" src={byteText} alt="byte text written in cool letters"/>
        <div className="navBar">
            <button class={(props.activeTab === "home") ? "activetab" : "tab"} onClick={() => props.setActiveTab("home")}>home</button>
            <button class={(props.activeTab === "contact") ? "activetab" : "tab"} onClick={() => props.setActiveTab("contact")}>contact</button>
            <button class={(props.activeTab === "case studies") ? "activetab" : "tab"} onClick={() => props.setActiveTab("case studies")}>case studies</button>
            <button class={(props.activeTab === "pricing") ? "activetab" : "tab"} onClick={() => props.setActiveTab("pricing")}>pricing</button>
        </div>
    </div>
    )
}
export default Header;